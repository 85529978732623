<template>
  <form id="payment-form" class="payment_form">
    <div id="link-authentication-element">
      <!--Stripe.js injects the Link Authentication Element-->
    </div>
    <div id="payment-element">
      <!--Stripe.js injects the Payment Element-->
    </div>
    <button id="submit">
      <div class="spinner hidden" id="spinner"></div>
      <span id="button-text">Pay now</span>
    </button>
    <div id="payment-message" class="hidden"></div>
  </form>
</template>

<!-- <script src="https://js.stripe.com/v3/"></script> -->
<script>
  import { loadStripe } from '@stripe/stripe-js';
  export default {
    data() {
      return {}
    },

    async mounted() {
      let model_type = this.$route.query.model_type
      let id = this.$route.query.id
      let package_type = this.$route.query.package_type
      let model_id = this.$route.query.model_id
      let stripeUrl = window.location.href.split('#')[0]
      var stripe = await loadStripe('pk_live_51Nv5OuAEUo9tZsahw3trNNg5bRCjG68JJ3HNoQkhp7C2YD1tG2sbOCCjU3ZfBkUjvph9pSrGyqUpkvYpL3OuGuDR0095hxmlU6')
      if (process.env.VUE_APP_MODE === 'development'|| process.env.VUE_APP_MODE === 'test') {
        stripe = await loadStripe('pk_test_51Nv5OuAEUo9tZsahBKwLNYLU8NDVigIxsk4mV9d8DgsRVunfKMOW41sN3kz2qJ3yAlDPOW9hg8ot0RzwPDgdHlAl00zmr5k7FP');
      }

      // The items the customer wants to buy
      const items = [{ id: "xl-tshirt" }];

      let elements;
      let clientSecretParent = this.$parent.$parent.clientSecret
      let order_no = this.$parent.$parent.order_no
      // console.log("this.$parent.$parent.clientSecret", this.$parent.$parent.clientSecret);

      initialize();
      checkStatus();

      setTimeout(() => {
        document
          .querySelector("#payment-form")
          .addEventListener("submit", handleSubmit);
      }, 50);

      let emailAddress = '';

      // Fetches a payment intent and captures the client secret
      async function initialize() {
        // const response = await fetch("http://127.0.0.1:4242/createPaymentIntent", {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json",'Access-Control-Allow-Origin':'*' },
        //   body: JSON.stringify({ items }),
        // });
        // const { clientSecret } = { clientSecret: "pi_3O4gsQAEUo9tZsah13WvDEzL_secret_aONgIx9E62Zyy4UWwauUzcFmm" };
        const { clientSecret } = { clientSecret: clientSecretParent };

        const appearance = { theme: 'stripe', };
        elements = stripe.elements({ appearance, clientSecret });

        const linkAuthenticationElement = elements.create("linkAuthentication");
        linkAuthenticationElement.mount("#link-authentication-element");

        linkAuthenticationElement.on('change', (event) => {
          emailAddress = event.value.email;
        });

        const paymentElementOptions = {
          layout: "tabs",
        };

        const paymentElement = elements.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
      }

      async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);

        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: `${stripeUrl}#/PayMoney?model_type=${model_type}&order_no=${order_no}&model_id=${model_id}&id=${id}&package_type=${package_type}`,
            receipt_email: emailAddress,
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          showMessage(error.message);
        } else {
          showMessage("An unexpected error occurred.");
        }

        setLoading(false);
      }

      // Fetches the payment intent status after payment submission
      async function checkStatus() {
        const clientSecret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        );

        if (!clientSecret) {
          return;
        }

        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

        switch (paymentIntent.status) {
          case "succeeded":
            showMessage("Payment succeeded!");
            break;
          case "processing":
            showMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            showMessage("Your payment was not successful, please try again.");
            break;
          default:
            showMessage("Something went wrong.");
            break;
        }
      }

      // ------- UI helpers -------

      function showMessage(messageText) {
        const messageContainer = document.querySelector("#payment-message");

        messageContainer.classList.remove("hidden");
        messageContainer.textContent = messageText;

        setTimeout(function () {
          messageContainer.classList.add("hidden");
          messageContainer.textContent = "";
        }, 4000);
      }

      // Show a spinner on payment submission
      function setLoading(isLoading) {
        if (isLoading) {
          // Disable the button and show a spinner
          document.querySelector("#submit").disabled = true;
          document.querySelector("#spinner").classList.remove("hidden");
          document.querySelector("#button-text").classList.add("hidden");
        } else {
          document.querySelector("#submit").disabled = false;
          document.querySelector("#spinner").classList.add("hidden");
          document.querySelector("#button-text").classList.remove("hidden");
        }
      }
    },

  }
</script>

<style scoped lang="scss">
  .payment_form {
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;

    .hidden {
      display: none;
    }

    #payment-message {
      color: rgb(105, 115, 134);
      font-size: 16px;
      line-height: 20px;
      padding-top: 12px;
      text-align: center;
    }

    #payment-element {
      margin-bottom: 24px;
    }

    /* Buttons and links */
    button {
      background: #5469d4;
      font-family: Arial, sans-serif;
      color: #ffffff;
      border-radius: 4px;
      border: 0;
      padding: 12px 16px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      display: block;
      transition: all 0.2s ease;
      box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
      width: 100%;
    }

    button:hover {
      filter: contrast(115%);
    }

    button:disabled {
      opacity: 0.5;
      cursor: default;
    }

    /* spinner/processing state, errors */
    .spinner,
    .spinner:before,
    .spinner:after {
      border-radius: 50%;
    }

    .spinner {
      color: #ffffff;
      font-size: 22px;
      text-indent: -99999px;
      margin: 0px auto;
      position: relative;
      width: 20px;
      height: 20px;
      box-shadow: inset 0 0 0 2px;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
    }

    .spinner:before,
    .spinner:after {
      position: absolute;
      content: "";
    }

    .spinner:before {
      width: 10.4px;
      height: 20.4px;
      background: #5469d4;
      border-radius: 20.4px 0 0 20.4px;
      top: -0.2px;
      left: -0.2px;
      -webkit-transform-origin: 10.4px 10.2px;
      transform-origin: 10.4px 10.2px;
      -webkit-animation: loading 2s infinite ease 1.5s;
      animation: loading 2s infinite ease 1.5s;
    }

    .spinner:after {
      width: 10.4px;
      height: 10.2px;
      background: #5469d4;
      border-radius: 0 10.2px 10.2px 0;
      top: -0.1px;
      left: 10.2px;
      -webkit-transform-origin: 0px 10.2px;
      transform-origin: 0px 10.2px;
      -webkit-animation: loading 2s infinite ease;
      animation: loading 2s infinite ease;
    }

  }

  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 600px) {
    .payment_form {
      width: 80vw;
      min-width: initial;
      padding: 20px;
    }
  }
</style>